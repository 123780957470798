import React from 'react';
import PropTypes from 'prop-types';
import ErrorBanner from '../../../components/ErrorBanner';

CancelledBanner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function CancelledBanner(props) {
  const { offer } = props;

  if (offer.data.cancelled) {
    return (
      <ErrorBanner
        title="Offer was cancelled for the following reason:"
        subtitle={offer.data.cancelledMessage || '[No reason provided]'}
      />
    );
  }

  return null;
}
