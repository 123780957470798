import React from 'react';
import _ from 'lodash';
import {
  BookOpenIcon,
  CakeIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  EyeIcon,
  LightBulbIcon,
  ShareIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import { useUser } from '@lore/auth';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import useDialogRouter from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogRouter';
import TabBarButton from '../../@communityshare/ui/navigation/TabBarButton';
import TabBar from '../../@communityshare/ui/navigation/TabBar';
import TabBarDropdownMenu from '../../@communityshare/ui/navigation/TabBarDropdownMenu';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../DialogManager';

export default function ProjectTabBar(props) {
  const { project } = props;

  const currentUser = useUser();

  const { id } = useDialogConfig();
  const router = useDialogRouter(id);

  const launch = useDialogLauncher();

  const buttonLinks = _.remove([
    {
      icon: LightBulbIcon,
      label: 'Idea',
      to: `/idea`
    },
    {
      icon: ShareIcon,
      label: 'Connect',
      to: `/invitations`
    },
    {
      icon: CakeIcon,
      label: 'Celebrate',
      to: `/evaluations`
    }
  ], entry => entry);

  const menuLinks = _.remove([
    {
      type: 'button',
      icon: EnvelopeIcon,
      label: 'Send reminder to invite more partners',
      onClick: () => launch(DialogManager.ReminderProjectInvite.Config(project.id)),
      active: false
    },
    {
      type: 'button',
      icon: EnvelopeIcon,
      label: 'Send reminder to finish describing project',
      onClick: () => launch(DialogManager.ReminderProjectComplete.Config(project.id)),
      active: false
    },
    {
      type: 'button',
      icon: EnvelopeIcon,
      label: 'Send reminder to close project',
      onClick: () => launch(DialogManager.ReminderProjectClose.Config(project.id)),
      active: false
    },
    {
      type: 'button',
      icon: EyeIcon,
      label: 'Change Visibility',
      onClick: () => launch(DialogManager.ProjectVisibility.Config(project.id)),
      active: false
    },
    {
      type: 'button',
      icon: BookOpenIcon,
      label: 'Show/hide in Past Projects gallery',
      onClick: () => launch(DialogManager.PastProjectVisibility.Config(project.id)),
      active: false
    },
    {
      type: 'button',
      icon: DocumentDuplicateIcon,
      label: 'Assign to Steward',
      onClick: () => launch(DialogManager.ProjectAssign.Config(project.id)),
      active: false
    },
    {
      type: 'button',
      icon: TrashIcon,
      label: 'Delete project',
      onClick: () => launch(DialogManager.ProjectDelete.Config(project.id)),
      active: false
    }
  ], entry => entry);

  return (
    <TabBar>
      {buttonLinks.map(function(link, index) {
        const active = (
          router.location.pathname.indexOf(link.to) === 0 ||
          link.to.indexOf(router.location.pathname) === 0
        );

        return (
          <TabBarButton
            key={index}
            active={active}
            onClick={() => router.navigate(link.to)}
            icon={link.icon}
            label={link.label}
            to={link.to}
          />
        );
      })}
      {currentUser.data.isSuperuser && (
        <TabBarDropdownMenu
          label="More actions"
          secret={true}
          links={menuLinks}
        />
      )}
    </TabBar>
  );
};
