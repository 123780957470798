import useEducators from './useEducators';

export default function useCommunityEngagedEducators(network, schoolStart, schoolEnd, currentTimestamp) {
  const didEngage = useEducators(network, schoolStart, schoolEnd, {
    pastEngagement: true
  });

  const didNotEngage = useEducators(network, schoolStart, schoolEnd, {
    pastEngagement: false
  });

  const didNotRespond = useEducators(network, schoolStart, schoolEnd, {
    pastEngagement: {
      $exists: false
    }
  });

  return {
    didEngage,
    didNotEngage,
    didNotRespond
  }
}
