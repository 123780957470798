import React from 'react';
import { useConnect } from '@lore/query-connect';
import REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function AssociatedWithEvent(props) {
  const { network, schoolStart, schoolEnd, titleOnly } = props;

  const previousSchoolStart = moment(schoolStart).subtract(1, 'year').toISOString();

  const previousSchools = useConnect('site.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEvents',
            relation: 'users.events',
            $where: {
              $and: REQUIRED_FIELDS.$and,
              // 'creator.isEducator': true,
              'creator.tags.name': 'Educator',
              networkId: network?.id,
              createdAt: {
                $lte: schoolStart,
                $gte: titleOnly ? undefined : previousSchoolStart
              }
            }
          }
        ],
        $where: {
          numberOfEvents: {
            $gte: 1
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const newSchools = useConnect('site.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEvents',
            relation: 'users.events',
            $where: {
              $and: REQUIRED_FIELDS.$and,
              // 'creator.isEducator': true,
              'creator.tags.name': 'Educator',
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        ],
        $where: {
          numberOfEvents: {
            $gte: 1
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    previousSchools.state === PayloadStates.FETCHING ||
    newSchools.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Associated with Event"
      />
    );
  }

  return (
    <ComparisonMetric
      name="Associated with Event"
      value={newSchools.meta.totalCount}
      previousValue={previousSchools.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
