import React from 'react';
import { PayloadStates } from '@lore/utils';
import _ from 'lodash';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import DonutChart from '../../dashboard/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import useActiveEducators from './_hooks/useActiveEducators';
import useInactiveEducators from './_hooks/useInactiveEducators';
import useSleepingEducators from './_hooks/useSleepingEducators';
import useCommunityEngagedEducators from './_hooks/useCommunityEngagedEducators';

export default function EducatorCommunityEngagedYear(props) {
  const { network, schoolStart, schoolEnd, subtitle, currentTimestamp } = props;

  const engagement = useCommunityEngagedEducators(network, schoolStart, schoolEnd, currentTimestamp);

  const requests = [
    {
      label: 'Engaged with the community before',
      partners: engagement.didEngage
    },
    // {
    //   label: 'Sleeping',
    //   partners: useSleepingEducators(network, schoolStart, schoolEnd, currentTimestamp)
    // },
    {
      label: 'Had not engaged with the community before',
      partners: engagement.didNotEngage
    },
    {
      label: 'Did not respond',
      partners: engagement.didNotRespond
    }
  ];

  const loading = _.reduce(requests, function(result, request) {
    return result || request.partners.state === PayloadStates.FETCHING;
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Educator Status"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.partners.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title={(
        <>
          Educator engaged community partners in curriculum/events before CommunityShare was available to them
        </>
      )}
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.blue['400'],
        colors.red['400'],
        colors.yellow['400']
      ]}
    />
  );
}
