import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import VirtualAvatar from '../../../../components/User/VirtualAvatar';
import classNames from '../../../../utils/classNames';
import useIntents from '../../../../hooks/useIntents';

Wrapper.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Wrapper(props) {
  const {
    user,
    creator,
    children,
    type = 'project'
  } = props;

  const { isEducator, isPartner } = useIntents(user);

  return (
    <div className={classNames(
      'flex flex-col sm:flex-row items-center rounded-xl shadow-lg p-8 space-x-0 space-y-6 sm:space-x-10 sm:space-y-0',
      isEducator ? 'bg-teacher' : 'bg-setup-tag'
    )}>
      <div>
        <VirtualAvatar size={36} user={user} />
      </div>
      <div className="flex-1 flex flex-col">
        <>
          <div className="text-3xl text-white font-bold mb-2">
            Ready to connect {creator.data.firstName} and {user.data.firstName}?
          </div>
          {type === 'offer' ? (
            <div className="text-white mb-4 max-w-xl">
              We'll email {type} details to the educator and ask them to reply to the
              partner directly. The partner can see and message the educator first if
              they log in to the platform.
            </div>
          ) : (
            <div className="text-white mb-4 max-w-xl">
              We'll email {type} details to the partner and ask them to reply to the
              educator directly. The educator can see and message the partner first if
              they log in to the platform.
            </div>
          )}
        </>
        {children}
      </div>
    </div>
  );
}
