import React from 'react';
import useNetwork from '../../../hooks/useNetwork';
import useGetCount from '../../explore/useGetCount';

// Projects
import allProjects from '../../projects-past/useQuery';
import reviewProjects from '../../projects-past-filtered/review/useQuery';
import visibleProjects from '../../projects-past-filtered/visible/useQuery';
import hiddenProjects from '../../projects-past-filtered/hidden/useQuery';

export default function getNavigationLinks() {
  return [
    { name: 'All', href: `/past-projects`, count: useGetCount(allProjects) },
    { name: 'Review', href: `/past-projects/review`, count: useGetCount(reviewProjects) },
    { name: 'Visible', href: `/past-projects/visible`, count: useGetCount(visibleProjects) },
    { name: 'Hidden', href: `/past-projects/hidden`, count: useGetCount(hiddenProjects) }
  ];
}
