import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

/*
 * Launchers
 */
import UserView from './_launchers/user_view';
import UserManage from './_launchers/user_manage';
import UserDisable from './_launchers/user_disable';
import UserInviteOffer from './_launchers/user_invite_offer';
import UserInviteProjectEvent from './_launchers/user_invite_project_event';

import ProjectManage from './_launchers/project_manage';
import ProjectVisibility from './_launchers/project_visibility';
import PastProjectVisibility from './_launchers/past_project_visibility';
import ProjectAssign from './_launchers/project_assign';
import ProjectDelete from './_launchers/project_delete';
import ProjectInvite from './_launchers/project_invite';
import ReminderProjectInvite from './_launchers/reminder_project_invite';
import ReminderProjectComplete from './_launchers/reminder_project_complete';
import ReminderProjectClose from './_launchers/reminder_project_close';

import EventManage from './_launchers/event_manage';
import EventVisibility from './_launchers/event_visibility';
import EventAssign from './_launchers/event_assign';
import EventDelete from './_launchers/event_delete';
import EventInvite from './_launchers/event_invite';
import ReminderEventInvite from './_launchers/reminder_event_invite';
import ReminderEventComplete from './_launchers/reminder_event_complete';
import ReminderEventClose from './_launchers/reminder_event_close';

import OfferManage from './_launchers/offer_manage';
import OfferVisibility from './_launchers/offer_visibility';
import OfferAssign from './_launchers/offer_assign';
import OfferDelete from './_launchers/offer_delete';
import OfferInvite from './_launchers/offer_invite';
import ReminderOfferInvite from './_launchers/reminder_offer_invite';
import ReminderOfferComplete from './_launchers/reminder_offer_complete';

import ClaimInvite from './_launchers/claim_invite';
import ClaimDecline from './_launchers/claim_decline';

import QuestionInvite from './_launchers/question_invite';
import QuestionDecline from './_launchers/question_decline';

import InvitationInvite from './_launchers/invitation_invite';
import InvitationDecline from './_launchers/invitation_decline';

import ReminderProfileComplete from './_launchers/reminder_profile_complete';

import ReminderClaimRespond from './_launchers/reminder_claim_respond';
import ReminderInvitationRespond from './_launchers/reminder_invitation_respond';
import ReminderQuestionRespond from './_launchers/reminder_question_respond';

import ReminderEvaluationProjectEducatorComplete from './_launchers/reminder_evaluation_project_educator_complete';
import ReminderEvaluationProjectPartnerComplete from './_launchers/reminder_evaluation_project_partner_complete';
import ReminderEvaluationEventEducatorComplete from './_launchers/reminder_evaluation_event_educator_complete';
import ReminderEvaluationEventPartnerComplete from './_launchers/reminder_evaluation_event_partner_complete';

import StewardInvitationInvite from './_launchers/steward_invitation_invite';
import StewardInvitationRevoke from './_launchers/steward_invitation_revoke';

import StaffInvite from './_launchers/staff_invite';
import StaffRevoke from './_launchers/staff_revoke';

import FiltersProject from './_launchers/filters_project';
import FiltersEvent from './_launchers/filters_event';
import FiltersOffer from './_launchers/filters_offer';
import FiltersPartner from './_launchers/filters_partner';
import FiltersEducator from './_launchers/filters_educator';

import NoteUpdate from './_launchers/note_update';

import NetworkCreate from './_launchers/network_create';
import NetworkUpdate from './_launchers/network_update';

import SiteCreate from './_launchers/site_create';
import SiteUpdate from './_launchers/site_update';

import SchoolUpdate from './_launchers/school_update';

import CSVDownload from './_launchers/csv_download';

import EvaluationProjectEducator from './_launchers/evaluation_project_educator';
import EvaluationProjectPartner from './_launchers/evaluation_project_partner';
import EvaluationEventEducator from './_launchers/evaluation_event_educator';
import EvaluationEventPartner from './_launchers/evaluation_event_partner';
import EvaluationProjectEducatorStory from './_launchers/evaluation_project_educator_story';
import EvaluationEventEducatorStory from './_launchers/evaluation_event_educator_story';

import FiltersEvaluationProjectEducator from './_launchers/filters_evaluation_project_educator';
import FiltersEvaluationProjectPartner from './_launchers/filters_evaluation_project_partner';
import FiltersEvaluationEventEducator from './_launchers/filters_evaluation_event_educator';
import FiltersEvaluationEventPartner from './_launchers/filters_evaluation_event_partner';
import FiltersUploadEvaluationProjectEducator from './_launchers/filters_upload_evaluation_project_educator';
import FiltersUploadEvaluationEventEducator from './_launchers/filters_upload_evaluation_event_educator';

import MobileNavigationMenu from './_launchers/mobile_navigation_menu';

import SelectNetwork from './_launchers/select_network';

import NetworkSiteCreate from './_launchers/network_site_create';
import NetworkSiteDelete from './_launchers/network_site_delete';

function format(type, params = {}, route = '/', options = {}) {
  return {
    id: uuidv4(),
    type: type,
    params: params,
    route: route,
    options: options
  };
}

export const DialogManager = {
  MobileNavigationMenu: {
    Key: 'mobile_navigation_menu',
    Config: function(id, route) {
      return format('mobile_navigation_menu', {
        // paramId: id
      }, route, { topAlign: true });
    },
    Launcher: MobileNavigationMenu
  },
  EvaluationProjectEducatorStory: {
    Key: 'evaluation_project_educator_story',
    Config: function(id, route) {
      return format('evaluation_project_educator_story', {
        evaluationProjectEducatorId: id
      }, route, { topAlign: true });
    },
    Launcher: EvaluationProjectEducatorStory
  },
  EvaluationEventEducatorStory: {
    Key: 'evaluation_event_educator_story',
    Config: function(id, route) {
      return format('evaluation_event_educator_story', {
        evaluationEventEducatorId: id
      }, route, { topAlign: true });
    },
    Launcher: EvaluationEventEducatorStory
  },
  EvaluationProjectEducator: {
    Key: 'evaluation_project_educator',
    Config: function(id, route) {
      return format('evaluation_project_educator', {
        evaluationProjectEducatorId: id
      }, route, { topAlign: true });
    },
    Launcher: EvaluationProjectEducator
  },
  EvaluationProjectPartner: {
    Key: 'evaluation_project_partner',
    Config: function(id, route) {
      return format('evaluation_project_partner', {
        evaluationProjectPartnerId: id
      }, route, { topAlign: true });
    },
    Launcher: EvaluationProjectPartner
  },
  EvaluationEventEducator: {
    Key: 'evaluation_event_educator',
    Config: function(id, route) {
      return format('evaluation_event_educator', {
        evaluationEventEducatorId: id
      }, route, { topAlign: true });
    },
    Launcher: EvaluationEventEducator
  },
  EvaluationEventPartner: {
    Key: 'evaluation_event_partner',
    Config: function(id, route) {
      return format('evaluation_event_partner', {
        evaluationEventPartnerId: id
      }, route, { topAlign: true });
    },
    Launcher: EvaluationEventPartner
  },

  CSVDownload: {
    Key: 'csv_download',
    Config: function(query) {
      return format('csv_download', {
        modelName: 'csvUser',
        query: JSON.stringify(query),
      });
    },
    Launcher: CSVDownload
  },
  ReminderProfileComplete: {
    Key: 'reminder_profile_complete',
    Config: function(id) {
      return format('reminder_profile_complete', {
        userId: id,
      });
    },
    Launcher: ReminderProfileComplete
  },
  FiltersProject: {
    Key: 'filters_project',
    Config: function(id) {
      return format('filters_project', {
        parentDialogId: id,
      });
    },
    Launcher: FiltersProject
  },
  FiltersEvent: {
    Key: 'filters_event',
    Config: function(id) {
      return format('filters_event', {
        parentDialogId: id,
      });
    },
    Launcher: FiltersEvent
  },
  FiltersOffer: {
    Key: 'filters_offer',
    Config: function(id) {
      return format('filters_offer', {
        parentDialogId: id,
      });
    },
    Launcher: FiltersOffer
  },
  FiltersPartner: {
    Key: 'filters_partner',
    Config: function(id, extras = {}) {
      return format('filters_partner', {
        parentDialogId: id,
        variant: extras.variant
      });
    },
    Launcher: FiltersPartner
  },
  FiltersEducator: {
    Key: 'filters_educator',
    Config: function(id, extras = {}) {
      return format('filters_educator', {
        parentDialogId: id,
        variant: extras.variant
      });
    },
    Launcher: FiltersEducator
  },
  FiltersEvaluationProjectEducator: {
    Key: 'filters_evaluation_project_educator',
    Config: function(id, extra = {}) {
      return format('filters_evaluation_project_educator', {
        parentDialogId: id,
        ...extra
      });
    },
    Launcher: FiltersEvaluationProjectEducator
  },
  FiltersEvaluationProjectPartner: {
    Key: 'filters_evaluation_project_partner',
    Config: function(id, extra = {}) {
      return format('filters_evaluation_project_partner', {
        parentDialogId: id,
        ...extra
      });
    },
    Launcher: FiltersEvaluationProjectPartner
  },
  FiltersEvaluationEventEducator: {
    Key: 'filters_evaluation_event_educator',
    Config: function(id, extra = {}) {
      return format('filters_evaluation_event_educator', {
        parentDialogId: id,
        ...extra
      });
    },
    Launcher: FiltersEvaluationEventEducator
  },
  FiltersEvaluationEventPartner: {
    Key: 'filters_evaluation_event_partner',
    Config: function(id, extra = {}) {
      return format('filters_evaluation_event_partner', {
        parentDialogId: id,
        ...extra
      });
    },
    Launcher: FiltersEvaluationEventPartner
  },
  FiltersUploadEvaluationProjectEducator: {
    Key: 'filters_upload_evaluation_project_educator',
    Config: function(id, extra = {}) {
      return format('filters_upload_evaluation_project_educator', {
        parentDialogId: id,
        ...extra
      });
    },
    Launcher: FiltersUploadEvaluationProjectEducator
  },
  FiltersUploadEvaluationEventEducator: {
    Key: 'filters_upload_evaluation_event_educator',
    Config: function(id, extra = {}) {
      return format('filters_upload_evaluation_event_educator', {
        parentDialogId: id,
        ...extra
      });
    },
    Launcher: FiltersUploadEvaluationEventEducator
  },

  UserView: {
    Key: 'user_view',
    Config: function(id, extra = {}) {
      return format('user_view', {
        userId: id,
        ...extra
      }, undefined, { topAlign: true });
    },
    Launcher: UserView
  },
  UserManage: {
    Key: 'user_manage',
    Config: function(id, route = '/') {
      return format('user_manage', {
        userId: id
      }, route, { topAlign: true });
    },
    Launcher: UserManage
  },
  UserDisable: {
    Key: 'user_disable',
    Config: function(id, route = '/') {
      return format('user_disable', {
        userId: id
      }, route, { topAlign: true });
    },
    Launcher: UserDisable
  },
  UserInviteOffer: {
    Key: 'user_invite_offer',
    Config: function(id, route = '/') {
      return format('user_invite_offer', {
        userId: id
      }, route, { topAlign: true });
    },
    Launcher: UserInviteOffer
  },
  UserInviteProjectEvent: {
    Key: 'user_invite_project_event',
    Config: function(id, route = '/') {
      return format('user_invite_project_event', {
        userId: id
      }, route, { topAlign: true });
    },
    Launcher: UserInviteProjectEvent
  },

  ProjectManage: {
    Key: 'project_manage',
    Config: function(id, route = '/invitations') {
      return format('project_manage', {
        projectId: id
      }, route, { topAlign: true });
    },
    Launcher: ProjectManage
  },
  ProjectVisibility: {
    Key: 'project_visibility',
    Config: function(id) {
      return format('project_visibility', {
        projectId: id
      });
    },
    Launcher: ProjectVisibility
  },
  PastProjectVisibility: {
    Key: 'past_project_visibility',
    Config: function(id) {
      return format('past_project_visibility', {
        projectId: id
      });
    },
    Launcher: PastProjectVisibility
  },
  ReminderProjectInvite: {
    Key: 'reminder_project_invite',
    Config: function(id) {
      return format('reminder_project_invite', {
        projectId: id
      });
    },
    Launcher: ReminderProjectInvite
  },
  ReminderProjectComplete: {
    Key: 'reminder_project_complete',
    Config: function(id) {
      return format('reminder_project_complete', {
        projectId: id
      });
    },
    Launcher: ReminderProjectComplete
  },
  ReminderProjectClose: {
    Key: 'reminder_project_close',
    Config: function(id) {
      return format('reminder_project_close', {
        projectId: id
      });
    },
    Launcher: ReminderProjectClose
  },
  ProjectAssign: {
    Key: 'project_assign',
    Config: function(id) {
      return format('project_assign', {
        projectId: id
      });
    },
    Launcher: ProjectAssign
  },
  ProjectDelete: {
    Key: 'project_delete',
    Config: function(id) {
      return format('project_delete', {
        projectId: id
      });
    },
    Launcher: ProjectDelete
  },
  ProjectInvite: {
    Key: 'project_invite',
    Config: function(id) {
      return format('project_invite', {
        projectId: id
      }, undefined, { topAlign: true });
    },
    Launcher: ProjectInvite
  },

  EventManage: {
    Key: 'event_manage',
    Config: function(id, route = '/invitations') {
      return format('event_manage', {
        eventId: id
      }, route, { topAlign: true });
    },
    Launcher: EventManage
  },
  EventVisibility: {
    Key: 'event_visibility',
    Config: function(id) {
      return format('event_visibility', {
        eventId: id
      });
    },
    Launcher: EventVisibility
  },
  ReminderEventInvite: {
    Key: 'reminder_event_invite',
    Config: function(id) {
      return format('reminder_event_invite', {
        eventId: id
      }, undefined, { topAlign: true });
    },
    Launcher: ReminderEventInvite
  },
  ReminderEventComplete: {
    Key: 'reminder_event_complete',
    Config: function(id) {
      return format('reminder_event_complete', {
        eventId: id
      });
    },
    Launcher: ReminderEventComplete
  },
  ReminderEventClose: {
    Key: 'reminder_event_close',
    Config: function(id) {
      return format('reminder_event_close', {
        eventId: id
      });
    },
    Launcher: ReminderEventClose
  },
  EventAssign: {
    Key: 'event_assign',
    Config: function(id) {
      return format('event_assign', {
        eventId: id
      });
    },
    Launcher: EventAssign
  },
  EventDelete: {
    Key: 'event_delete',
    Config: function(id) {
      return format('event_delete', {
        eventId: id
      });
    },
    Launcher: EventDelete
  },
  EventInvite: {
    Key: 'event_invite',
    Config: function(id) {
      return format('event_invite', {
        eventId: id
      }, undefined, { topAlign: true });
    },
    Launcher: EventInvite
  },

  OfferManage: {
    Key: 'offer_manage',
    Config: function(id, route = '/invitations') {
      return format('offer_manage', {
        offerId: id
      }, route, { topAlign: true });
    },
    Launcher: OfferManage
  },
  OfferVisibility: {
    Key: 'offer_visibility',
    Config: function(id) {
      return format('offer_visibility', {
        offerId: id
      });
    },
    Launcher: OfferVisibility
  },
  ReminderOfferInvite: {
    Key: 'reminder_offer_invite',
    Config: function(id) {
      return format('reminder_offer_invite', {
        offerId: id
      }, undefined, { topAlign: true });
    },
    Launcher: ReminderOfferInvite
  },
  ReminderOfferComplete: {
    Key: 'reminder_offer_complete',
    Config: function(id) {
      return format('reminder_offer_complete', {
        offerId: id
      });
    },
    Launcher: ReminderOfferComplete
  },
  OfferAssign: {
    Key: 'offer_assign',
    Config: function(id) {
      return format('offer_assign', {
        offerId: id
      });
    },
    Launcher: OfferAssign
  },
  OfferDelete: {
    Key: 'offer_delete',
    Config: function(id) {
      return format('offer_delete', {
        offerId: id
      });
    },
    Launcher: OfferDelete
  },
  OfferInvite: {
    Key: 'offer_invite',
    Config: function(id) {
      return format('offer_invite', {
        offerId: id
      }, undefined, { topAlign: true });
    },
    Launcher: OfferInvite
  },

  ClaimDecline: {
    Key: 'claim_decline',
    Config: function(id) {
      return format('claim_decline', {
        claimId: id,
      });
    },
    Launcher: ClaimDecline
  },
  ClaimInvite: {
    Key: 'claim_invite',
    Config: function(offerId, userId) {
      return format('claim_invite', {
        offerId: offerId,
        userId: userId,
      });
    },
    Launcher: ClaimInvite
  },
  ReminderClaimRespond: {
    Key: 'reminder_claim_respond',
    Config: function(id) {
      return format('reminder_claim_respond', {
        claimId: id,
      });
    },
    Launcher: ReminderClaimRespond
  },

  InvitationInvite: {
    Key: 'invitation_invite',
    Config: function(eventId, userId) {
      return format('invitation_invite', {
        eventId: eventId,
        userId: userId,
      });
    },
    Launcher: InvitationInvite
  },
  InvitationDecline: {
    Key: 'invitation_decline',
    Config: function(id) {
      return format('invitation_decline', {
        invitationId: id,
      });
    },
    Launcher: InvitationDecline
  },
  ReminderInvitationRespond: {
    Key: 'reminder_invitation_respond',
    Config: function(id) {
      return format('reminder_invitation_respond', {
        invitationId: id,
      });
    },
    Launcher: ReminderInvitationRespond
  },

  QuestionDecline: {
    Key: 'question_decline',
    Config: function(id) {
      return format('question_decline', {
        questionId: id,
      });
    },
    Launcher: QuestionDecline
  },
  QuestionInvite: {
    Key: 'question_invite',
    Config: function(projectId, userId) {
      return format('question_invite', {
        projectId: projectId,
        userId: userId,
      });
    },
    Launcher: QuestionInvite
  },
  ReminderQuestionRespond: {
    Key: 'reminder_question_respond',
    Config: function(id) {
      return format('reminder_question_respond', {
        questionId: id,
      });
    },
    Launcher: ReminderQuestionRespond
  },

  ReminderEvaluationProjectEducatorComplete: {
    Key: 'reminder_evaluation_project_educator_complete',
    Config: function(id) {
      return format('reminder_evaluation_project_educator_complete', {
        evaluationProjectEducatorId: id
      });
    },
    Launcher: ReminderEvaluationProjectEducatorComplete
  },
  ReminderEvaluationProjectPartnerComplete: {
    Key: 'reminder_evaluation_project_partner_complete',
    Config: function(id) {
      return format('reminder_evaluation_project_partner_complete', {
        evaluationProjectPartnerId: id
      });
    },
    Launcher: ReminderEvaluationProjectPartnerComplete
  },
  ReminderEvaluationEventEducatorComplete: {
    Key: 'reminder_evaluation_event_educator_complete',
    Config: function(id) {
      return format('reminder_evaluation_event_educator_complete', {
        evaluationEventEducatorId: id
      });
    },
    Launcher: ReminderEvaluationEventEducatorComplete
  },
  ReminderEvaluationEventPartnerComplete: {
    Key: 'reminder_evaluation_event_partner_complete',
    Config: function(id) {
      return format('reminder_evaluation_event_partner_complete', {
        evaluationEventPartnerId: id
      });
    },
    Launcher: ReminderEvaluationEventPartnerComplete
  },

  StewardInvitationInvite: {
    Key: 'steward_invitation_invite',
    Config: function(id) {
      return format('steward_invitation_invite');
    },
    Launcher: StewardInvitationInvite
  },
  StewardInvitationRevoke: {
    Key: 'steward_invitation_revoke',
    Config: function(id) {
      return format('steward_invitation_revoke', {
        stewardInvitationId: id
      });
    },
    Launcher: StewardInvitationRevoke
  },

  StaffInvite: {
    Key: 'staff_invite',
    Config: function(id) {
      return format('staff_invite');
    },
    Launcher: StaffInvite
  },
  StaffRevoke: {
    Key: 'staff_revoke',
    Config: function(id) {
      return format('staff_revoke', {
        userId: id
      });
    },
    Launcher: StaffRevoke
  },

  NoteUpdate: {
    Key: 'note_update',
    Config: function(id) {
      return format('note_update', {
        noteId: id
      });
    },
    Launcher: NoteUpdate
  },

  NetworkCreate: {
    Key: 'network_create',
    Config: function(id) {
      return format('network_create', {
        // no params
      });
    },
    Launcher: NetworkCreate
  },
  NetworkUpdate: {
    Key: 'network_update',
    Config: function(id) {
      return format('network_update', {
        networkId: id
      });
    },
    Launcher: NetworkUpdate
  },

  SiteCreate: {
    Key: 'site_create',
    Config: function(id) {
      return format('site_create', {
        // no params
      });
    },
    Launcher: SiteCreate
  },
  SiteUpdate: {
    Key: 'site_update',
    Config: function(id) {
      return format('site_update', {
        siteId: id
      });
    },
    Launcher: SiteUpdate
  },

  SchoolUpdate: {
    Key: 'school_update',
    Config: function(id) {
      return format('school_update', {
        schoolId: id
      });
    },
    Launcher: SchoolUpdate
  },

  SelectNetwork: {
    Key: 'select_network',
    Config: function(id, route) {
      return format('select_network', {
        networkId: id
      }, route, { topAlign: true });
    },
    Launcher: SelectNetwork
  },

  NetworkSiteCreate: {
    Key: 'network_site_create',
    Config: function(id) {
      return format('network_site_create', {
        networkId: id
      }, undefined, { topAlign: true });
    },
    Launcher: NetworkSiteCreate
  },
  NetworkSiteDelete: {
    Key: 'network_site_delete',
    Config: function(id) {
      return format('network_site_delete', {
        networkSiteId: id
      });
    },
    Launcher: NetworkSiteDelete
  },
}

export const DialogManagerQueryParamMap = _.reduce(DialogManager, function(result, value, key) {
  result[value.Key] = {
    ...value,
    Key: key
  };
  return result;
}, {});
