import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '../../../hooks/@lore/query-connect';
import ReminderDialogTemplate from '../_common_reminders/ReminderDialogTemplate';
import useUserProfileMissingFields from '../../hooks/useUserProfileMissingFields';
import Loader from '../../components/Loader';

Dialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { user } = props;

  const missingFields = useUserProfileMissingFields(user);

  const reminders = useConnect('reminder.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          type: 'Complete'
        }
      }
    }
  });

  let _data = {
    type: 'Complete',
    userId: user.id,
    recipientId: user.id
  };

  if (!missingFields) {
    return (
      <Loader />
    );
  }

  return (
    <ReminderDialogTemplate
      title="Send reminder to complete profile"
      subtitle={(
        <span>
          This will send an email {user.data.firstName ? `to ${user.data.firstName}` : ''} nudging them to complete their profile.
        </span>
      )}
      reminders={reminders}
      _data={_data}
      MAX_COUNT={3}
      onClose={props.onClose}
      blockerText={(
        missingFields.profile.length === 0 &&
        missingFields.interests.length === 0
      ) && (
        <div className="text-base text-cs-gray-700">
          User has a complete profile. You cannot send a reminder.
        </div>
      )}
    />
  );
};
