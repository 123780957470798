import React from 'react';
import PropTypes from 'prop-types';
import EmptyState from '../../_common/EmptyState';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../DialogManager';
import useIntents from '../../../hooks/useIntents';

export default function Invite(props) {
  const { user } = props;

  const { isEducator, isPartner } = useIntents(user);

  const launch = useDialogLauncher();

  function onInviteProjectEvent() {
    launch(DialogManager.UserInviteProjectEvent.Config(user.id));
    // show(
    //   <InviteProjectEventDialog
    //     user={user}
    //   />, { template: 'dialog' }
    // );
  }

  function onInviteOffer() {
    launch(DialogManager.UserInviteOffer.Config(user.id));
    // show(
    //   <InviteOfferDialog
    //     user={user}
    //   />, { template: 'dialog' }
    // );
  }

  if (isEducator) {
    return (
      <div className="hover:shadow hover:opacity-80 cursor-pointer" onClick={onInviteOffer}>
        <EmptyState
          title="Invite to Offer"
          // subtitle="The more people that are invited, the better the chances of finding a match!"
          color="blue"
        />
      </div>
    );
  }

  return (
    <div className="hover:shadow hover:opacity-80 cursor-pointer" onClick={onInviteProjectEvent}>
      <EmptyState
        title="Invite to Project/Event"
        // subtitle="The more people that are invited, the better the chances of finding a match!"
        color="blue"
      />
    </div>
  );
};
