import React from 'react';
import PropTypes from 'prop-types';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { useConfig } from '@lore/config';
import Tooltip from '../../../components/Tooltip';

export default function ShareLink(props) {
  const { project } = props;

  const config = useConfig();

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="Platform Link" icon={false}>
        <ClipboardDocumentListIcon className="h-6 w-6 text-cs-orange-500" />
      </Tooltip>
      <a href={`${config.custom.platformUrl()}/review-past-project/${project.id}`} target="_blank">
        .../review-past-project/{project.id}
      </a>
    </div>
  );
};
