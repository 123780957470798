import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import _ from 'lodash';
import Pagination from '../../components/pagination-new/Pagination';
import useScrollToTop from '../../hooks/useScrollToTop';
import useFilterCount from '../../hooks/useFilterCount';
import useQuery from './useQuery';
// import DistanceFilter from './DistanceFilter';
import FilterButton from '../_common_filters/FilterButton';
import PaginationInformation from '../../components/pagination-new/PaginationInformation';
// import TypeFilter from './TypeFilter';
import NetworkFilter from '../projects/NetworkFilter';
import SortBy from '../projects/SortBy';
import { attributeKeys } from '../projects/queryUtils';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import { DialogManager } from '../../dialogs_new/DialogManager';

Projects.propTypes = {
  children: PropTypes.func.isRequired
};

export default function Projects(props) {
  const { router, children } = props;
  const { location } = router;

  const search = parse(location.search);
  const filterCount = useFilterCount(search, _.filter(attributeKeys, key => [
    'networks',
    'locationCondition'
  ].indexOf(key) < 0));

  const node = useRef();
  const scrollToTop = useScrollToTop();

  const pageSize = 4;

  const result = useQuery({
    search,
    pageSize
  });

  const launch = useDialogLauncher();
  const config = useDialogConfig();

  function showFilters() {
    launch(DialogManager.FiltersProject.Config(config?.id));
  }

  return (
    <>
      {/*<div ref={node} className="flex items-center justify-between">*/}
      {/*  <PaginationInformation*/}
      {/*    pageSize={pageSize}*/}
      {/*    totalCount={result.meta?.totalCount}*/}
      {/*  />*/}
      {/*</div>*/}
      <div ref={node} className="flex flex-wrap gap-4 justify-between items-end">
        <PaginationInformation
          pageSize={pageSize}
          totalCount={result.meta?.totalCount}
        />
        {/*<div className="flex space-x-4">*/}
        {/*  <NetworkFilter />*/}
        {/*  /!*<TypeFilter />*!/*/}
        {/*  /!*<DistanceFilter />*!/*/}
        {/*</div>*/}
        <div className="flex flex-wrap gap-4">
          {/*<SortBy/>*/}
          {/*<FilterButton*/}
          {/*  count={filterCount}*/}
          {/*  onClick={showFilters}*/}
          {/*/>*/}
        </div>
      </div>
      {children(result)}
      <div className="flex flex-wrap gap-4 items-center justify-between">
        <PaginationInformation
          pageSize={pageSize}
          totalCount={result.meta?.totalCount}
        />
        <Pagination
          pageSize={pageSize}
          totalCount={result.meta?.totalCount}
          onNavigate={() => scrollToTop(node)}
        />
      </div>
    </>
  );
}
