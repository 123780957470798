import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import CancelledTag from '../../../pages/_components/CancelledTag';
import CompletedTag from '../../../pages/_components/CompletedTag';
import ExpiredTag from '../../../pages/_components/ExpiredTag';
import Date from './Date';
import Visibility from './Visibility';
import Creator from './Creator';
import Site from './Site';
import ShareLink from './ShareLink';
import Partners from '../../../pages/events/Row/Partners';
import CreatorPhone from './CreatorPhone';
import CreatorEmail from './CreatorEmail';
import TimeRange from './TimeRange';
import Slots from './Slots';
import Filled from './Filled';
import { useUser } from '@lore/auth';
import Network from '../../../pages/events/Event/Network';
import CancelledBanner from './CancelledBanner';

export default function Header(props) {
  const { event } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-2">
        <div className="flex space-x-4">
          <div className="text-sm text-gray-500">
            Event
          </div>
          <div className="flex space-x-2">
            <ExpiredTag resource={event}/>
            <CancelledTag resource={event}/>
            <CompletedTag resource={event}/>
          </div>
        </div>
        <div className="text-3xl font-bold text-gray-700">
          {event.data.title || activity.data.name || '...'}
        </div>
        <div className="text-gray-500">
          {event.data.interactionDescription}
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <Date event={event} />
            <TimeRange event={event} />
            <Slots event={event} />
            <Filled event={event} />
            <Creator event={event} />
            <CreatorPhone event={event} />
            <CreatorEmail event={event} />
            <Site event={event} />
            {currentUser.data.isStaff && (
              <Network event={event} />
            )}
            <Visibility event={event} />
            <ShareLink event={event} />
          </div>
          <Partners event={event} />
        </div>
      </div>
      <CancelledBanner event={event} />
    </div>
  );
};
