import React from 'react';
import Header from './Header';
import classNames from 'classnames';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../../components/Loader';
import Job from '../Job';
import Divider from '../../../../forms/_fields_v2/Divider';

export default function Organization(props) {
  const { organization, className, children } = props;

  const jobs = useConnect('job.find', {
    where: {
      eager: {
        $where: {
          organizationId: organization.id,
          // siteId: {
          //   $exists: false
          // },
          // 'user.isEducator': true,
          'user.tags.name': 'Educator'
        }
      }
    }
  });

  if (jobs.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      // 'hover:bg-cs-orange-50 cursor-pointer',
      className
    )}>
      <div className="flex flex-col py-4 px-4 space-y-2">
        <div className="pr-10">
          <Header organization={organization} />
        </div>
        <Divider />
        {jobs.data.map(function(job) {
          return(
            <Job key={job.id} job={job} />
          );
        })}
      </div>
      {children}
    </div>
  );
}
