import React from 'react';
import PropTypes from 'prop-types';
import useActiveProjectsForUser from '../useActiveProjectsForUser';
import usePastProjectsForUser from '../usePastProjectsForUser';
import useActiveEventsForUser from '../useActiveEventsForUser';
import usePastEventsForUser from '../usePastEventsForUser';
import useActiveOffersForUser from '../useActiveOffersForUser';
import usePastOffersForUser from '../usePastOffersForUser';
import useStoriesForUser from '../useStoriesForUser';
import useActiveQuestionsForUser from '../useActiveQuestionsForUser';
import usePastQuestionsForUser from '../usePastQuestionsForUser';
import useActiveInvitationsForUser from '../useActiveInvitationsForUser';
import usePastInvitationsForUser from '../usePastInvitationsForUser';
import useIntents from '../../../hooks/useIntents';

ExperiencesBanner.propTypes = {
  user: PropTypes.object.isRequired
};

export default function ExperiencesBanner(props) {
  const { user } = props;

  const { isEducator, isPartner } = useIntents(user);

  const activeProjects = useActiveProjectsForUser(user);
  const pastProjects = usePastProjectsForUser(user);
  const activeEvents = useActiveEventsForUser(user);
  const pastEvents = usePastEventsForUser(user);
  const activeOffers = useActiveOffersForUser(user);
  const pastOffers = usePastOffersForUser(user);
  const stories = useStoriesForUser(user);
  const activeQuestions = useActiveQuestionsForUser(user);
  const pastQuestions = usePastQuestionsForUser(user);
  const activeInvitations = useActiveInvitationsForUser(user);
  const pastInvitations = usePastInvitationsForUser(user);

  const hasEducatorExperiences = isEducator && (
    activeProjects.data.length > 0 ||
    pastProjects.data.length > 0 ||
    activeEvents.data.length > 0 ||
    pastEvents.data.length > 0 ||
    stories.data.length > 0
  );

  const hasPartnerExperiences = isPartner && (
    activeOffers.data.length > 0 ||
    pastOffers.data.length > 0 ||
    stories.data.length > 0 ||
    activeQuestions.data.length > 0 ||
    pastQuestions.data.length > 0 ||
    activeInvitations.data.length > 0 ||
    pastInvitations.data.length > 0
  );

  if (
    hasEducatorExperiences ||
    hasPartnerExperiences
  ) {
    return (
      <div className="flex bg-cs-purple-500 bg-opacity-5 space-x-4 -m-px border border-cs-purple-500 rounded-cs-10 px-6 py-5 shadow-cs-flat">
        <div className="text-4xl">
          🎉
        </div>
        <div className="space-y-0.5">
          <div className="text-lg font-semibold text-cs-purple-500">
            See the experiences I participated in below!
          </div>
          <div className="text-sm text-cs-purple-500 opacity-90">
            Check out the projects, events, and impact stories that I collaborated on.
          </div>
        </div>
      </div>
    );
  }

  return null;
}
