import React from 'react';
import LazyMetrics from '../LazyMetrics';
import EngagementByMonth from './EngagementByMonth';
import SharedProject from './SharedProject';
import SharedEvent from './SharedEvent';
import InvitedToOffer from './InvitedToOffer';
import InvitedToOfferAndResponded from './InvitedToOfferAndResponded';
import CreatedInviteToOffer from './CreatedInviteToOffer';
import EducatorGrowthByMonth from './EducatorGrowthByMonth';
import CompositeEducatorGrowth from './CompositeEducatorGrowth';
import EducatorStatusYear from './EducatorStatusYear';
import EducatorCommunityEngagedYear from './EducatorCommunityEngagedYear';

export default function Educators(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        // title="Growth"
        columns={1}
      >
        {/*<EducatorGrowthByMonth {...params} />*/}
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <EducatorStatusYear
            subtitle="For signups up to and including this school year"
            {...params}
            schoolStart={undefined}
          />
          <EducatorStatusYear
            subtitle="For new signups this school year"
            {...params}
          />
        </div>
        <EducatorGrowthByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositeEducatorGrowth {...params} showTotals={false} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Engagement"
        columns={1}
      >
        <EngagementByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <SharedProject {...params} />
          <SharedEvent {...params} />
          <InvitedToOffer {...params} />
          <InvitedToOfferAndResponded {...params} />
          <CreatedInviteToOffer {...params} />
        </div>
      </LazyMetrics>
      {/*<div className="border border-orange-500 border-dashed rounded-lg p-4">*/}
      <LazyMetrics
        title="Background"
        columns={1}
      >
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <EducatorCommunityEngagedYear
            subtitle="For new signups this school year"
            {...params}
          />
        </div>
      </LazyMetrics>
      {/*</div>*/}
    </>
  );
}
