import React from 'react';
import { Helmet } from 'react-helmet-async';
import Projects from './Projects';
import useRouter from '../../hooks/useRouter';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Results from '../_common_results/Results';
import Project from '../projects/Project';
import Filters from '../../forms/filters_projects';
import { FolderIcon } from '@heroicons/react/24/outline';
import MobileNavigation from '../projects/MobileNavigation';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Past Projects</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Explore', 'Past Projects']}
        title="All Past Projects"
        description={(
          <>
            This page shows all past projects in your network. Use the filters to narrow the
            list. Find a filter you want to see again in the future? Bookmark the page
            or save a link to the URL.
          </>
        )}
      />
      <Filters router={router} />
      <Projects router={router}>
        {function(result) {
          return (
            <Results result={result} columns={1} resource="projects" emptyIcon={FolderIcon}>
              {function(project, index) {
                return (
                  <Project
                    key={index}
                    project={project}
                  />
                );
              }}
            </Results>
          );
        }}
      </Projects>
    </div>
  );
}
