import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import networks from './networks';
import name from './name';
import state from './state';
import district from './district';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in_subquery: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  // networks,
  name,
  state,
  district
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
