import React from 'react';
import PropTypes from 'prop-types';
import { BookOpenIcon, CheckIcon, ClipboardDocumentListIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import Setting from '../_common_visibility/Setting';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    project
  } = props;

  const educator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  let activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  if (!project.data.activityId) {
    activity = {
      state: PayloadStates.RESOLVED,
      data: {
        name: 'No Title'
      }
    };
  }

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <BookOpenIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Change visibility in Past Projects gallery"
      subtitle={(
        <>
          You are about to change the visibility of the
          project <span className="text-cs-gray-900 font-semibold">{project.data.title || activity.data.name || '...'}</span> created
          by <span className="text-cs-gray-900 font-semibold">{educator.data.firstName} {educator.data.lastName}</span>.
        </>
      )}
      onClose={onClose}
      alert={alert}
      body={(
        <>
          <div className="grid grid-cols-1 sm:grid-cols-1 gap-3">
            <Setting
              icon={ClipboardDocumentListIcon}
              title="Review"
              description={(
                <>
                  This project will be flagged for review. Projects under
                  review are not shown in the Past Projects gallery.
                </>
              )}
              onClick={() => onChange('galleryStatus', 'Review')}
              selected={data.galleryStatus === 'Review'}
            />
            <Setting
              icon={CheckIcon}
              title="Visible"
              description={(
                <>
                  This project will be shown in the Past Projects gallery.
                </>
              )}
              onClick={() => onChange('galleryStatus', 'Show')}
              selected={data.galleryStatus === 'Show'}
            />
            <Setting
              icon={EyeSlashIcon}
              title="Hidden"
              description={(
                <>
                  This project will NOT be displayed in the Past Projects gallery.
                </>
              )}
              onClick={() => onChange('galleryStatus', 'Hide')}
              selected={data.galleryStatus === 'Hide'}
            />
          </div>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Save
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
